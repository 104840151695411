import { useEffect, useRef, forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Card, Title, Text, Cell, CellText, Link as MagritteLink, VSpacing, Button } from '@hh.ru/magritte-ui';
import {
    BubbleSquareOutlinedSize24,
    MagnifierHeartFilledSize24,
    WalletOutlinedSize24,
    HeartOutlinedSize24,
    UserDoubleOutlinedSize24,
    PlusOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import FormatMoney from 'src/components/FormatMoney';
import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { paymentNotAvailableSites } from 'src/models/topLevelSite';

import BalanceAccess from 'src/components/IndexPageEmployer/components/EmployerEventList/BalanceAccess';
import BalanceOptions from 'src/components/IndexPageEmployer/components/EmployerEventList/BalanceOptions/BalanceOptions';
import BalancePfp from 'src/components/IndexPageEmployer/components/EmployerEventList/BalancePfp';
import BalanceVacancies from 'src/components/IndexPageEmployer/components/EmployerEventList/BalanceVacancies';
import EventStatistics from 'src/components/IndexPageEmployer/components/EmployerEventList/EventStatistics';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
    moveApplicantsFromHeader: 'move_applicants_from_header',
};

const MOVE_APPLCANTS_FROM_HEADER_INFOTIP = 'moveApplicantsFromHeader';

const WithInfotip = ({ children, tipContent, renderTooltip, buttonText }) => {
    const [visible, setVisible] = useState(true);

    const handleClose = () => {
        Notices.markAsViewed(MOVE_APPLCANTS_FROM_HEADER_INFOTIP);
        setVisible(false);
    };

    if (!renderTooltip) {
        return children;
    }

    return (
        <MagritteInfotip
            name={MOVE_APPLCANTS_FROM_HEADER_INFOTIP}
            placement="bottom-center"
            closeByClickOutside={false}
            render={({ activatorRef }) => <div ref={activatorRef}>{children}</div>}
            visible={visible}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            showBannerOnMobile={false}
            useDefaultHost
        >
            <div>{tipContent}</div>
            <VSpacing default={16} />
            <Button size="small" style="contrast" mode="secondary" onClick={handleClose}>
                {buttonText}
            </Button>
        </MagritteInfotip>
    );
};

const formatCounter = (counter) => {
    if (counter > 1000) {
        return `${Math.floor(counter / 1000)}K`;
    }

    return counter;
};

const IndexRaw = ({ trls, fwdRef }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const userStats = useSelector((state) => state.userStats);
    const permissions = useSelector((state) => state.permissions);
    const employerIndexPage = useSelector((state) => state.employerIndexPage);
    const topLevelSite = useSelector((state) => state.topLevelSite);
    const vacancyResponsesLinkRef = useRef(null);
    const autoSearchLinkRef = useRef(null);
    const balanceAccountLinkRef = useRef(null);
    const balanceIncreaseLinkRef = useRef(null);
    const createVacancyLinkRef = useRef(null);
    const targetResumesRef = useRef(null);
    const favResumesRef = useRef(null);
    const userFeatures = useSelector((state) => state.userFeatures) || [];
    const moveApplicantsFromHeader = useFeatureEnabled(Features.moveApplicantsFromHeader);
    const isAuthorizedEmployerMenuExpC = useExperiment('redesign_authorized_employer_menu_c', true, false);

    const hasFavResumesFeature = userFeatures.includes('favResumes');
    const hasTargetResumesFeature = userFeatures.includes('targetResumes');
    const hasTargetResumesAgencyFeature = userFeatures.includes('targetResumesAgency');
    const shouldRenderTargetResumes = hasTargetResumesFeature || hasTargetResumesAgencyFeature;

    const getTipContent = () => {
        if (shouldRenderTargetResumes && hasFavResumesFeature) {
            return trls[IndexRaw.trls.tipTargetAndFavResumes];
        }

        return trls[IndexRaw.trls.tipFavResumes];
    };

    const isZp = useIsZarplataPlatform();
    const tipContent = getTipContent();

    const sendShownAnalytics = useCallback((element, name) => {
        Analytics.sendHHEventElementShown(element, { name });
    }, []);

    const sendClickAnalytics = (name) => {
        Analytics.sendHHEventButtonClick(name);
    };

    const getEmployerTargetResumesEventCounter = () => {
        if (userStats?.['new-wanna-work'] > 0) {
            return (
                <span className="event-counter event-counter_new-events">{`+${formatCounter(
                    userStats['new-wanna-work']
                )}`}</span>
            );
        }

        if (userStats?.['wanna-work']) {
            return <span className="event-counter">{formatCounter(userStats['wanna-work'])}</span>;
        }

        return null;
    };

    const getTargetResumes = () => {
        if (shouldRenderTargetResumes) {
            return (
                <WithInfotip tipContent={tipContent} buttonText={trls[IndexRaw.trls.closeTip]} renderTooltip>
                    <Link
                        to="/employer/targetresumes"
                        data-qa="my-events-targetresumes"
                        ref={targetResumesRef}
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? () => sendClickAnalytics('my_events_target_resumes')
                                : undefined
                        }
                    >
                        <Cell
                            left={<UserDoubleOutlinedSize24 />}
                            right={
                                <Text typography="label-2-regular" style="secondary">
                                    {getEmployerTargetResumesEventCounter()}
                                </Text>
                            }
                        >
                            <CellText>
                                {hasTargetResumesFeature
                                    ? trls[IndexRaw.trls.targetResumes]
                                    : trls[IndexRaw.trls.incomingResumes]}
                            </CellText>
                        </Cell>
                    </Link>
                </WithInfotip>
            );
        }

        return null;
    };

    const getAvailableMoney = () => {
        const billingSummary = employerIndexPage?.oldEmployer?.billingSummary;

        if (!billingSummary) {
            return null;
        }

        if (billingSummary.availableWithoutBlockedMoney === 0 && !paymentNotAvailableSites.includes(topLevelSite)) {
            return (
                <div ref={balanceIncreaseLinkRef}>
                    <MagritteLink
                        href="/employer/invoice/payment"
                        data-qa="my-events-balance-putmoney"
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? indexPageEmployerAnalytics.myEventsBalanceIncreaseClick
                                : undefined
                        }
                    >
                        {trls[IndexRaw.trls.putMoney]}
                    </MagritteLink>
                </div>
            );
        }

        return (
            <span className="my-events__count">
                <Text typography="label-2-regular" style="secondary">
                    <FormatMoney currency={billingSummary.currency}>
                        {billingSummary.availableWithoutBlockedMoney}
                    </FormatMoney>
                </Text>
            </span>
        );
    };

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled) {
            if (vacancyResponsesLinkRef.current) {
                indexPageEmployerAnalytics.myEventsVacancyResponsesShown(vacancyResponsesLinkRef.current);
            }
            if (autoSearchLinkRef.current) {
                indexPageEmployerAnalytics.myEventsAutoSearchShown(autoSearchLinkRef.current);
            }
            if (balanceAccountLinkRef.current) {
                indexPageEmployerAnalytics.myEventsBalanceAccountShown(balanceAccountLinkRef.current);
            }
            if (createVacancyLinkRef.current) {
                indexPageEmployerAnalytics.myEventsCreateVacancyShown(createVacancyLinkRef.current);
            }
            if (balanceIncreaseLinkRef.current) {
                indexPageEmployerAnalytics.myEventsBalanceIncreaseShown(balanceIncreaseLinkRef.current);
            }

            if (targetResumesRef.current) {
                sendShownAnalytics(targetResumesRef.current, 'my_events_target_resumes');
            }
            if (favResumesRef.current) {
                sendShownAnalytics(favResumesRef.current, 'my_events_fav_resumes');
            }
        }
    }, [employerMainPageAnalyticsEnabled, sendShownAnalytics]);

    return (
        <div className="my-events my-events-wrapper" data-qa="my-events">
            <Card showBorder padding={24} borderRadius={24} stretched>
                <div className="my-events__content" ref={fwdRef}>
                    <div className="my-events__cells">
                        <div className="my-events__title">
                            <Title Element="h2" size="small">
                                {trls[IndexRaw.trls.title]}
                            </Title>
                            {isAuthorizedEmployerMenuExpC && permissions.includes('CreateVacancy') ? (
                                <div ref={createVacancyLinkRef}>
                                    <MagritteLink
                                        href="/employer/vacancy/create"
                                        data-qa="my-events-createvacancy"
                                        iconLeft={PlusOutlinedSize16}
                                        onClick={
                                            employerMainPageAnalyticsEnabled
                                                ? indexPageEmployerAnalytics.myEventsCreateVacancyClick
                                                : undefined
                                        }
                                    >
                                        {trls[IndexRaw.trls.newVacancyShort]}
                                    </MagritteLink>
                                </div>
                            ) : null}
                        </div>

                        <Link
                            ref={vacancyResponsesLinkRef}
                            to="/employer/vacancies"
                            data-qa="my-events-vacancyresponses"
                            onClick={
                                employerMainPageAnalyticsEnabled
                                    ? indexPageEmployerAnalytics.myEventsVacancyResponsesClick
                                    : undefined
                            }
                        >
                            <Cell left={<BubbleSquareOutlinedSize24 />}>
                                <CellText>{trls[IndexRaw.trls.vacancyResponses]}</CellText>
                            </Cell>
                        </Link>

                        <Link
                            ref={autoSearchLinkRef}
                            to="/resumesavedsearch"
                            data-qa="my-events-autosearches"
                            onClick={
                                employerMainPageAnalyticsEnabled
                                    ? indexPageEmployerAnalytics.myEventsAutoSearchClick
                                    : undefined
                            }
                        >
                            <Cell
                                left={<MagnifierHeartFilledSize24 />}
                                right={
                                    userStats?.['new-resumes-saved-search'] ? (
                                        <span className="event-counter event-counter_new-events">
                                            {`+${userStats['new-resumes-saved-search']}`}
                                        </span>
                                    ) : null
                                }
                            >
                                <CellText>{trls[IndexRaw.trls.autosearches]}</CellText>
                            </Cell>
                        </Link>

                        {moveApplicantsFromHeader && hasFavResumesFeature && (
                            <WithInfotip
                                tipContent={tipContent}
                                renderTooltip={!shouldRenderTargetResumes}
                                buttonText={trls[IndexRaw.trls.closeTip]}
                            >
                                <Link
                                    to="/employer/resumefolders"
                                    data-qa="my-events-fav-resumes"
                                    ref={favResumesRef}
                                    onClick={
                                        employerMainPageAnalyticsEnabled
                                            ? () => sendClickAnalytics('my_events_fav_resumes')
                                            : undefined
                                    }
                                >
                                    <Cell left={<HeartOutlinedSize24 />}>
                                        <CellText>{trls[IndexRaw.trls.favResumes]}</CellText>
                                    </Cell>
                                </Link>
                            </WithInfotip>
                        )}

                        {moveApplicantsFromHeader && getTargetResumes()}

                        <div className="my-events__item_balance">
                            <Cell
                                data-qa="my-events-balance"
                                left={<WalletOutlinedSize24 />}
                                right={getAvailableMoney()}
                            >
                                <Link
                                    ref={balanceAccountLinkRef}
                                    to="/employer/account"
                                    data-qa="my-events-balance-account"
                                    onClick={
                                        employerMainPageAnalyticsEnabled
                                            ? indexPageEmployerAnalytics.myEventsBalanceAccountClick
                                            : undefined
                                    }
                                >
                                    <CellText>{trls[IndexRaw.trls.balance]}</CellText>
                                </Link>
                            </Cell>
                        </div>

                        <BalanceVacancies />
                        <BalanceAccess />
                        {isZp && <BalanceOptions />}
                        <BalancePfp />
                        {employerIndexPage?.oldEmployer?.canViewStatPortal && <EventStatistics />}
                    </div>

                    {permissions.includes('CreateVacancy') && !isAuthorizedEmployerMenuExpC ? (
                        <div ref={createVacancyLinkRef}>
                            <VSpacing default={24} />
                            <MagritteLink
                                href="/employer/vacancy/create"
                                data-qa="my-events-createvacancy"
                                onClick={
                                    employerMainPageAnalyticsEnabled
                                        ? indexPageEmployerAnalytics.myEventsCreateVacancyClick
                                        : undefined
                                }
                            >
                                {trls[IndexRaw.trls.newVacancy]}
                            </MagritteLink>
                        </div>
                    ) : null}
                </div>
            </Card>
        </div>
    );
};

WithInfotip.propTypes = {
    children: PropTypes.node,
    tipContent: PropTypes.node,
    renderTooltip: PropTypes.bool,
    buttonText: PropTypes.string,
};

IndexRaw.propTypes = {
    trls: PropTypes.object,
    fwdRef: PropTypes.object,
};

IndexRaw.trls = {
    title: 'index.headers.events',
    vacancyResponses: 'index.events.vacancyResponses',
    autosearches: 'index.events.autosearches',
    targetResumes: 'index.events.targetResumes',
    incomingResumes: 'index.employer.billing.incomingResumes',
    balance: 'index.employer.billing.balance',
    newVacancy: 'index.events.button.newVacancy',
    newVacancyShort: 'index.events.button.newVacancyShort',
    putMoney: 'index.employer.billing.putMoney',
    favResumes: 'index.employer.favResumes',
    resumePicks: 'index.employer.resumePicks',
    tipTargetAndFavResumes: 'index.employer.tips.targetAndFavResumes',
    tipFavResumes: 'index.employer.tips.favResumes',
    closeTip: 'index.employer.tips.closeTip',
};

const Translated = translation(IndexRaw);
const Index = (props, ref) => <Translated {...props} fwdRef={ref} />;

export default forwardRef(Index);
