import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import MultiLineContainer from 'bloko/blocks/chips/MultiLineContainer';
import Conversion from 'bloko/blocks/conversion';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ProfessionalRoleTreeItem } from 'src/models/professionalRoleTree';
import { SuitableVacancies } from 'src/models/suitableVacancies';
import fetcher from 'src/utils/fetcher';

import SearchProfareaChipsItem from './SearchProfareaChipsItem';

const setSuitableVacancies = makeSetStoreField('suitableVacancies');
const setSearchClusters = makeSetStoreField('searchClusters');
const setSearchClustersOrder = makeSetStoreField('searchClustersOrder');

const TrlKeys = {
    showAllButton: {
        one: 'index.anonymous.profarea.select.one',
        some: 'index.anonymous.profarea.select.some',
        many: 'index.anonymous.profarea.select.many',
    },
    hideButton: 'index.anonymous.profarea.select.hide',
    title: 'index.anonymous.profarea.title',
    subtitle: 'index.anonymous.profarea.subtitle',
};

declare global {
    interface FetcherGetApi {
        '/shards/vacancy/suitable_vacancies': {
            queryParams: {
                professionalRole: string[];
                isAnonymousSearchResultExp?: boolean;
                fromParam?: string;
            };
            response: SuitableVacancies;
        };
    }
}

// Фильтруем категорию "другое"
const OTHER_CATEGORY_ID = 'category-27';
const VISIBLE_CATEGORIES_NUMBER = 10;

interface SearchProfareaChipsProps {
    onSelectRole: (roles: Record<string, ProfessionalRoleTreeItem[]>) => void;
}

const SearchProfareaChips: TranslatedComponent<SearchProfareaChipsProps> = ({ trls, onSelectRole }) => {
    const professionalRoleTree = useSelector((state) =>
        state.professionalRoleTree.items.filter((item) => item.id !== OTHER_CATEGORY_ID)
    );
    const [selectedCategories, setSelectedCategories] = useState<Record<string, ProfessionalRoleTreeItem[]>>({});
    const dispatch = useDispatch();
    const [shouldShowAll, setShouldShowAll] = useState(false);

    const getSuitableVacanciesByProfarea = (ids: string[]) => {
        return fetcher
            .get('/shards/vacancy/suitable_vacancies', {
                params: {
                    professionalRole: ids,
                    isAnonymousSearchResultExp: true,
                    fromParam: 'main',
                },
            })
            .then((result) => {
                dispatch(setSuitableVacancies({ ...result }));
                dispatch(setSearchClusters({ ...result.searchClusters }));
                dispatch(setSearchClustersOrder({ ...result.searchClustersOrder }));
            });
    };

    const handleSelectProfroles = (categoryId: string, roles: ProfessionalRoleTreeItem[]) => {
        const selectedCategoriesToUpdate = { ...selectedCategories, [categoryId]: roles };
        let rolesToGet: ProfessionalRoleTreeItem[] = [];
        Object.keys(selectedCategoriesToUpdate).forEach((category) => {
            if (!selectedCategoriesToUpdate[category].length) {
                delete selectedCategoriesToUpdate[category];
            } else {
                rolesToGet = rolesToGet.concat(selectedCategoriesToUpdate[category]);
            }
        });
        setSelectedCategories(selectedCategoriesToUpdate);
        onSelectRole(selectedCategoriesToUpdate);
        return getSuitableVacanciesByProfarea(rolesToGet.map((role) => role.id));
    };

    const profareaTreeToRender = useMemo(() => {
        return shouldShowAll ? professionalRoleTree : professionalRoleTree.slice(0, VISIBLE_CATEGORIES_NUMBER);
    }, [shouldShowAll, professionalRoleTree]);

    return (
        <Card padding={24} borderRadius={16} showBorder>
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.subtitle]}
            </Text>
            <VSpacing default={16} />
            <Text typography="title-4-semibold" style="primary">
                {trls[TrlKeys.title]}
            </Text>
            <VSpacing default={24} />
            <MultiLineContainer>
                {profareaTreeToRender.map((item, index) => (
                    <SearchProfareaChipsItem
                        key={item.id}
                        index={index + 1}
                        item={item}
                        onSelect={(categoryId, roles) => handleSelectProfroles(categoryId, roles)}
                    />
                ))}

                <Link appearance={LinkAppearance.Pseudo} onClick={() => setShouldShowAll(!shouldShowAll)}>
                    {!shouldShowAll ? (
                        <Conversion
                            one={trls[TrlKeys.showAllButton.one]}
                            some={trls[TrlKeys.showAllButton.some]}
                            many={trls[TrlKeys.showAllButton.many]}
                            format={(trl) =>
                                format(trl, { '{0}': professionalRoleTree.length - VISIBLE_CATEGORIES_NUMBER })
                            }
                            value={professionalRoleTree.length}
                            hasValue={false}
                        />
                    ) : (
                        <>{trls[TrlKeys.hideButton]}</>
                    )}
                </Link>
            </MultiLineContainer>
        </Card>
    );
};

export default translation(SearchProfareaChips);
