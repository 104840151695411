import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { GridColumn, GridRow, useBreakpoint, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import ApplicantActivityCard from 'src/components/Applicant/ActivityCard';
import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';
import { BannerObject, BannerPlace } from 'src/models/banners';
import { UXFeedback } from 'src/utils/uxfeedback';

import Actions from 'src/components/IndexPageApplicant/Actions';
import EventList from 'src/components/IndexPageApplicant/EventList';
import RecommendedVacanciesList from 'src/components/IndexPageApplicant/RecommendedVacancies/RecommendedVacancies';

const ApplicantDashboard: FC<PropsWithChildren> = () => {
    const applicantInfo = useSelector((state) => state.applicantInfo);
    const bannersDashboard = useSelector((state) => state.banners[BannerPlace.DASHBOARD]);
    const isGamificationExp = useSelector((state) => state.isApplicantMainGamificationExp);
    const { isGtS } = useBreakpoint();

    useEffect(() => {
        UXFeedback.sendEvent('applicant_main_page_facelift');
    }, []);

    return (
        <GridRow>
            <GridColumn xs={4} s={8} m={4} l={3}>
                <div className="index-dashboard-applicant-sidebar" data-qa="applicant-index-dashboard-sidebar">
                    <VSpacingContainer default={12} gteS={16}>
                        {isGamificationExp && <ApplicantActivityCard iconType="chevron" showBorder />}
                        <EventList />
                        <Actions
                            hasResumes={applicantInfo?.total > 0}
                            hasFinishedResumes={applicantInfo?.finished > 0}
                        />
                    </VSpacingContainer>
                    <VSpacing default={32} />
                    {isGtS && (
                        <VSpacingContainer default={16}>
                            {bannersDashboard?.map((banner: BannerObject) => (
                                <AdsExternalBanner {...banner} key={banner.id} />
                            ))}
                        </VSpacingContainer>
                    )}
                </div>
            </GridColumn>
            <GridColumn xs={0} s={0} m={0} l={1} />
            <GridColumn xs={4} s={8} m={8} l={8}>
                <RecommendedVacanciesList />
            </GridColumn>
        </GridRow>
    );
};

export default ApplicantDashboard;
