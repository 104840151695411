import { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { H3Section } from 'bloko/blocks/header';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import AreaSwitcherModal from 'src/components/SupernovaSearch/AreaSwitcherModal';
import MagritteSearchSuggest from 'src/components/SupernovaSearch/MagritteSearchSuggest';
import SearchSuggest from 'src/components/SupernovaSearch/SearchSuggest';
import VacancySearchHiddenFields from 'src/components/SupernovaSearch/VacancySearchHiddenFields';
import useSearchBaseState from 'src/components/SupernovaSearch/useSearchBaseState';
import useSendFormSubmitEvent from 'src/components/SupernovaSearch/useSendFormSubmitEvent';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import { extractRequestUrlFromForm } from 'src/models/anonymousSignUp/utils';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

import Onboarding from 'src/components/IndexPageAnonymous/Onboarding';

interface SearchNavItem extends NavItem {
    action: string;
}

const TrlKeys = {
    headerText: 'supernova.navi.jobSearch',
    searchIn: 'index.dashboard.in',
};

const DashboardMobileSearch: TranslatedComponent<{ withBackdrop?: boolean }> = ({ trls, withBackdrop }) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const searchBaseState = useSearchBaseState();
    const dispatch = useDispatch();

    // TODO: После перевода на магритт модалки авторизации выпилить этот ресет компонента строки поиска.
    // https://jira.hh.ru/browse/HH-222290.
    const [shouldResetSuggest, resetSuggest] = useState(false);

    const searchName = SupernovaSearchName.Vacancies;
    const searchProps: SearchNavItem | undefined = useSelector(
        ({ headerMenu }) =>
            headerMenu
                ?.find((menuItem) => menuItem.name === 'searchForm')
                ?.subItems?.find((menuItem) => menuItem.name === searchName) as SearchNavItem
    );
    const formRef = useRef<HTMLFormElement>(null);
    const supernovaSearchAreaTrl = useSelector(({ supernovaSearchArea }) => supernovaSearchArea.translations.area);
    const isOnboardingExp = useSelector(({ anonymousOnboarding }) => anonymousOnboarding.isOnboardingExp);
    const sendFormSubmitEvent = useSendFormSubmitEvent(searchName, true);
    const [areaSwitchVisible, setAreaSwitchVisible, setAreaSwitchHidden] = useOnOffState(false);

    const isMagritte = useMagritte();

    const onFormSubmit = useCallback(() => {
        sendFormSubmitEvent();
        if (mainPageSignUpEnable) {
            const backUrl = extractRequestUrlFromForm(formRef.current as HTMLFormElement);
            dispatch(openAnonymousSignUpModal(backUrl));
            resetSuggest(true);
        } else {
            formRef.current?.requestSubmit();
        }
    }, [dispatch, mainPageSignUpEnable, sendFormSubmitEvent]);

    if (!searchProps) {
        return null;
    }

    const isMagritteSearch = searchBaseState.isMagritte;
    const content = (
        <div className="supernova-dashboard-mobile">
            <div className="supernova-dashboard-mobile-header supernova-dashboard-mobile-header_inline">
                <Text size={TextSize.Large}>
                    <div className="supernova-dashboard-mobile-inline-title">
                        <H3Section lite>{trls[TrlKeys.headerText]}</H3Section>
                    </div>{' '}
                    {trls[TrlKeys.searchIn]}
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        Element="button"
                        kind={LinkKind.Tertiary}
                        onClick={setAreaSwitchVisible}
                    >
                        {supernovaSearchAreaTrl}
                    </Link>
                </Text>
            </div>
            {isOnboardingExp ? (
                <Onboarding isMobile />
            ) : (
                <Form
                    onSubmit={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                        }

                        sendFormSubmitEvent();
                    }}
                    action={searchProps.action}
                    method="GET"
                    ref={formRef}
                >
                    <VacancySearchHiddenFields />
                    {isMagritteSearch ? (
                        <MagritteSearchSuggest
                            key={Number(shouldResetSuggest)}
                            searchName={searchName}
                            submitForm={onFormSubmit}
                        />
                    ) : (
                        <SearchSuggest
                            isEmployerSearch={false}
                            searchName={searchName}
                            large={false}
                            searchInputDefaultValue=""
                            submitForm={onFormSubmit}
                            isDashboardMobileSearch
                            isXs
                        />
                    )}
                </Form>
            )}
        </div>
    );

    return (
        <div className={withBackdrop ? 'supernova-dashboard-mobile-wrapper' : ''}>
            {isMagritte ? (
                <div>{content}</div>
            ) : (
                <ColumnsWrapper>
                    <Column xs="4" s="0" m="0" l="0">
                        {content}
                    </Column>
                </ColumnsWrapper>
            )}
            <AreaSwitcherModal visible={areaSwitchVisible} onClose={setAreaSwitchHidden} />
        </div>
    );
};

export default translation(DashboardMobileSearch);
