import React, { useState, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Alert, Button, Text, VSpacing, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { GiftOutlinedSize24, MinusCircleFilledSize24, CheckCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useActivateFreeService from 'src/api/price/useActivateFreeService';
import paths from 'src/app/routePaths';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';

import useRejectActivation from 'src/components/FreeVpplOnboardingModal/api/useRejectActivation';

const SERVICE_NAME = 'super_vppl';

const TrlKeys = {
    successSnackbarText: 'free-super-vppl-banner.success-snackbar-text',
    successSnackbarLink: 'free-super-vppl-banner.success-snackbar-link',
    somethingWrong: 'something-wrong',
    title: 'free-super-vppl-banner.title',
    text: 'free-super-vppl-banner.text',
    activate: 'free-super-vppl-banner.activate-button',
    close: 'free-super-vppl-banner.close-button',
};

const FreeVpplOnboardingModal: TranslatedComponent = ({ trls }) => {
    const showFreeVpplOnboardingModal = useSelectorNonNullable(
        ({ showFreeVpplOnboardingModal }) => showFreeVpplOnboardingModal
    );
    const [alertVisible, setAlertOpen] = useState(showFreeVpplOnboardingModal);

    const rejectActivationRequest = useRejectActivation();

    const handleClose = () => {
        Analytics.sendHHEventButtonClick('Astrakhan_modal_close');
        setAlertOpen(false);
        void rejectActivationRequest();
    };

    const { addNotification } = useNotification();
    const [activateFreeService, loading] = useActivateFreeService({
        onSuccess: () => {
            setAlertOpen(false);
            addNotification(Notification, {
                props: {
                    children: (
                        <>
                            <Text typography="label-2-regular" style="contrast">
                                {trls[TrlKeys.successSnackbarText]}
                            </Text>
                            <VSpacing default={16} />
                            <MagritteLink
                                Element={Link}
                                typography="subtitle-1-semibold"
                                style="contrast"
                                to={paths.vacancyCreate}
                            >
                                {trls[TrlKeys.successSnackbarLink]}
                            </MagritteLink>
                        </>
                    ),
                    showClose: true,
                    addon: <CheckCircleOutlinedSize24 initial="positive" />,
                },
            });
        },
        onError: () => {
            setAlertOpen(false);
            Analytics.sendHHEventButtonClick('Astrakhan_modal_close');
            addNotification(Notification, {
                props: {
                    children: <>{trls[TrlKeys.somethingWrong]}</>,
                    showClose: false,
                    addon: <MinusCircleFilledSize24 initial="negative" />,
                },
            });
        },
    });

    const activateFreeVppl = () => {
        void activateFreeService(SERVICE_NAME);
        Analytics.sendHHEventButtonClick('Astrakhan_modal_activate');
    };

    useEffect(() => {
        if (alertVisible) {
            Analytics.sendHHEvent('element_shown', { elementName: 'Astrakhan_modal_shown' });
        }
    }, [alertVisible]);

    return (
        <Alert
            visible={alertVisible}
            onClose={handleClose}
            title={<div>{trls[TrlKeys.title]}</div>}
            description={trls[TrlKeys.text]}
            layout="vertical"
            icon={<GiftOutlinedSize24 initial="accent" />}
            iconStyle="accent"
            buttons={
                <>
                    <Button onClick={activateFreeVppl} mode="primary" style="accent" loading={loading}>
                        {trls[TrlKeys.activate]}
                    </Button>
                    <Button onClick={handleClose} mode="secondary" disabled={loading}>
                        {trls[TrlKeys.close]}
                    </Button>
                </>
            }
        />
    );
};

export default translation(FreeVpplOnboardingModal);
